import React from 'react';
import MaxWidthWrapper from '../../../component/MaxWidthWrapper';

const PrivacyPolicy = () => {
    return (
        <MaxWidthWrapper className='font-inter'>


            <div className="py-10 sm:py-16 lg:py-24">

            <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className="text-3xl font-bold leading-tight text-richblue-200 sm:text-4xl lg:text-5xl">
                        BID-DRIVE Privacy Policy
                    </h2>

                </div>


                <h2 className="text-xl font-bold mb-4">1. Overview</h2>
                <p className="mb-4">
                    BID-DRIVE Europe cares about your privacy. This Privacy Policy is designed to help you obtain information about our privacy practices
                    and to help you understand your privacy choices when you use our sites and services. Our aim is to protect and process your personal data
                    with equal care in a lawful and transparent manner. (You; as a customer, possible future customer, website visitor or other natural person in
                    contact with us. Us; as BID-DRIVE Europe group including BID-DRIVE Europe Holding NV, BID-DRIVE Europe NV, BID-DRIVE Belgium NV, BID-DRIVE
                    Nederland B.V., BID-DRIVE Deutschland GmbH, BID-DRIVE France SAS, BID-DRIVE Italia S.R.L., BID-DRIVE Subastas España, S.L.). Contact details
                    can be found below at the end of this Policy.
                </p>

                <h2 className="text-xl font-bold mb-4">2. Definitions</h2>
                <p className="mb-4">
                    <strong>Personal data:</strong> Any information relating to an identified or identifiable natural person (data subject); an identifiable
                    natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification
                    number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural,
                    or social identity of that natural person. Personal data may directly or indirectly identify you.
                </p>
                <p className="mb-4">
                    <strong>Processing:</strong> Any operation or set of operations which is performed on personal data or on sets of personal data, whether or not
                    by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use,
                    disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
                </p>
                <p className="mb-4">
                    <strong>Controller:</strong> The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the
                    purposes and means of the processing of personal data. BID-DRIVE Europe is the controller as regards personal data.
                </p>
                <p className="mb-4">
                    <strong>Processor:</strong> A natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.
                </p>

                <h2 className="text-xl font-bold mb-4">3. What Personal Data We Collect and Process?</h2>
                <p className="mb-4">
                    The types of personal data we typically collect and process include:
                </p>
                <ul className="list-disc ml-6 mb-4">
                    <li><strong>Identification Data:</strong> To register as a BID-DRIVE Europe customer, we collect your company name, address, phone number, email address, and VAT number. If you wish to buy a product or service, you must provide a copy of your ID and proof of trade certification.</li>
                    <li><strong>Transactional Data:</strong> If you wish to buy a product or service, we collect additional financial information such as your bank account number.</li>
                    <li><strong>Visiting Information:</strong> We trace certain information based on your visit to our site and store certain types of information whenever you interact with us through our channels (e.g., websites, smartphone apps).</li>
                </ul>

                <h2 className="text-xl font-bold mb-4">4. Why We Use Your Personal Data?</h2>
                <p className="mb-4">
                    In principle, we will use your personal data:
                </p>
                <ul className="list-disc ml-6 mb-4">
                    <li>As part of performing a contract</li>
                    <li>To comply with legal and regulatory provisions</li>
                    <li>For the legitimate interests of our company to function as a commercial business</li>
                    <li>To provide you with relevant products and services when we have obtained your consent</li>
                    <li>Optimisation of the performance of our services</li>
                    <li>Prevention and detection of legal or regulatory breaches</li>
                </ul>

                <h2 className="text-xl font-bold mb-4">5. How Long We Keep Your Personal Data?</h2>
                <p className="mb-4">
                    We do not keep your personal data any longer than necessary for the purposes for which it was collected. For prospective customers, we keep
                    the personal data for 2 years. Customer and supplier data is kept during the term of the contract, and for the period legally required thereafter.
                </p>
                <p className="mb-4">
                    We respect the retention requirements stipulated by applicable laws (e.g., 7 years for invoices). In certain instances, we may be obliged by
                    a competent authority or due to ongoing litigation to keep certain data longer than described above. In such cases, we only keep the data as long
                    as strictly required.
                </p>

                <h2 className="text-xl font-bold mb-4">6. With Whom Do We Share Your Personal Data?</h2>
                <p className="mb-4">
                    We share your personal data within the BID-DRIVE Europe group and, in limited instances, outside the group. We may transfer personal data to other
                    BID-DRIVE affiliates or trusted third parties like internet service providers, cloud services, transport companies, or financial institutions.
                    Personal data is adequately protected if transferred outside the European Economic Area (EEA).
                </p>

                <h2 className="text-xl font-bold mb-4">7. Your Security Matters</h2>
                <p className="mb-4">
                    BID-DRIVE is committed to taking all reasonable and appropriate measures to safeguard the personal data that we hold from loss, misuse, or unauthorized
                    access. We have a security governance framework and restrict access to personal information to those employees who require it to fulfill their job responsibilities.
                </p>

                <h2 className="text-xl font-bold mb-4">8. Your Rights About Your Personal Data</h2>
                <p className="mb-4">
                    You have several rights regarding your personal data, including the right to access, rectify, erase, restrict or object to the processing of your data,
                    and the right to move your personal data (data portability). You can contact us to exercise these rights.
                </p>
            </div>
            </div>
        </MaxWidthWrapper>
    );
};

export default PrivacyPolicy;
