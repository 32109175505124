import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { apiConnector } from "../service/apiconnector";
import { useToast } from "../component/ui/use-toast";
import { useDispatch } from "react-redux";
import { setSignUpData } from "../slices/otpSlice";
import Img3 from "../assests/img3.svg";
import { Button } from "../component/ui/button";
import { Input } from "../component/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../component/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { BiShowAlt, BiHide } from "react-icons/bi";
import MaxWidthWrapper from "../component/MaxWidthWrapper";
import Navbar from "../component/Navbar";
import { IoIosArrowBack } from "react-icons/io";
import SEO  from '../service/helmet';


const formSchema = z
  .object({
    email: z.string().email("Invalid email address"),
    password: z.string().min(6, "Password must be at least 6 characters long"),
    confirmPassword: z
      .string()
      .min(6, "Password must be at least 6 characters long"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

const Sendotp = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
  };
  const form = useForm({ resolver: zodResolver(formSchema) });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const { toast } = useToast();

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSubmit = async (data) => {
    setLoading(true);

    try {
      
      const email = data.email.toLowerCase();

      dispatch(setSignUpData(email));

      const endpoint =
        location.pathname === "/forgot_password"
          ? "/api/forgot_password"
          : "/api/sendotp";

      const result = await apiConnector(
        "POST",
        process.env.REACT_APP_BASE_URL + endpoint,
        { email: data.email , password: data.password , confirmPassword: data.confirmPassword} 
      );

      if (result.data && !result.data.success) {
        toast({
          variant: "destructive",
          title: result.data.message || "Failed to Submit",
        });
        throw new Error(result.data.message);
      }

      toast({
        title: "OTP sent successfully",
        description: "Check your email for the OTP",
      });

      navigate(
        location.pathname === "/forgot_password"
          ? "/change_password"
          : "/sign-up"
      );
    } catch (err) {
      
      const errorMessage = err?.response?.data?.message;

      if (errorMessage.toLowerCase().includes("email")) {
        form.setError("email", {
          type: "manual",
          message: errorMessage, // Set the error message for email
        });
      } else if (errorMessage.toLowerCase().includes("password")) {
        form.setError("password", {
          type: "manual",
          message: errorMessage, // Set the error message for password
        });
      } else {
        toast({
          variant: "destructive",
          title: errorMessage,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const title = "Sign In to Your Bid-Drive Account | Secure Car Auction Platform";
const description = "Access your Bid-Drive account to bid on cars, manage your listings, and explore exclusive deals. Sign in to continue your car auction journey securely.";
const keywords = "sign in Bid-Drive, Bid-Drive login, car auction account access, car auction platform login, secure car auctions, online car auction sign in, Bid-Drive user login, access car auctions";
const image = "https://res.cloudinary.com/dgty4nzfo/image/upload/v1730490025/ProfileImages/hnk0cn9pv6bz3tnaadnb.png";
const url = "https://bid-drive.com/sendotp";


  return (
    <>

<SEO 
    title={title}
    description={description}
    image={image}
    url={url}
    keywords={keywords}
/>



    <div className="max-w-screen bg-richblue-100 md:bg-white h-screen w-full   mb-0 font-inter  ">
      <div className="p-6 sm:hidden">
        <Button
          variant="btn"
          onClick={goBack}
          className="font-bold p-0  h-10 w-10 rounded-md"
        >
          <IoIosArrowBack className="text-xl " />
        </Button>
      </div>

      <div className="grid grid-cols-1 bg-white rounded-t-3xl md:grid-cols-2 justify-center md:justify-end h-full  items-center gap-8">
        <div className="flex flex-col justify-center items-center m-0">
          <div className="mb-2 text-richblue-200 font-normal text-center ">
            <h1 className="text-4xl font-bold">
              {location.pathname === "/forgot_password"
                ? "Change your"
                : "Create your Account"}
              <span className="font-bold text-4xl text-richblue-100">
                {location.pathname === "/forgot_password" ? " Password" : ""}
              </span>
            </h1>

            <h1>
              <div className="font-bold text-4xl text-richblue-100">
                {location.pathname === "/forgot_password"
                  ? ""
                  : "For Bid-drive"}
              </div>
            </h1>
          </div>

          <div className="p-4">
            <ul className="flex justify-end gap-2 pr-4 text-richblue-200 font-[400] text-lg">
              <li>Already a member?</li>
              <li className="text-richblue-100">
                <Link to="/sign-in">Log in</Link>
              </li>
            </ul>
          </div>

          <div className="flex flex-wrap-reverse w-8/12 justify-center items-center mx-auto ">
            <div className="m-10 text-richblue-900">
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(handleSubmit)}
                  className="flex flex-col gap-2 text-lg font-normal relative space-y-2"
                >
                  <FormField
                    control={form.control}
                    className="text-lg font-normal font-inter m-0"
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-lg font-normal font-inter p-0 m-0">
                          Email
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="email"
                            placeholder="Enter your email"
                            className="border-[1px] ring-0 rounded-md p-2 w-72 focus:outline-0"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    className="text-lg font-normal font-inter m-0"
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-lg font-normal font-inter  p-0 m-0">
                          Password
                        </FormLabel>
                        <FormControl>
                          <div className="relative">
                            <Input
                              type={showPassword.password ? "text" : "password"}
                              placeholder="Enter your password"
                              className="border-[1px] rounded-md ring-0 p-2 w-72 focus:outline-0"
                              {...field}
                            />
                            <button
                              type="button"
                              onClick={() =>
                                togglePasswordVisibility("password")
                              }
                              className="absolute inset-y-0 right-4 pr-3 flex items-center"
                            >
                              {showPassword.password ? (
                                <BiHide />
                              ) : (
                                <BiShowAlt />
                              )}
                            </button>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    className="text-lg font-normal font-inter m-0"
                    name="confirmPassword"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-lg font-normal font-inter p-0 m-0">
                          Confirm Password
                        </FormLabel>
                        <FormControl>
                          <div className="relative">
                            <Input
                              type={
                                showPassword.confirmPassword
                                  ? "text"
                                  : "password"
                              }
                              placeholder="Confirm your password"
                              className="border-[1px] rounded-md ring-0 p-2 w-72 focus:outline-0"
                              {...field}
                            />
                            <button
                              type="button"
                              onClick={() =>
                                togglePasswordVisibility("confirmPassword")
                              }
                              className="absolute inset-y-0 right-4 pr-3 flex items-center"
                            >
                              {showPassword.confirmPassword ? (
                                <BiHide />
                              ) : (
                                <BiShowAlt />
                              )}
                            </button>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className="flex items-center justify-center mt-8">
                    <Button
                      type="submit"
                      disabled={loading}
                      variant="btn"
                      className="drop-shadow-xl w-[300px] py-0 px-0 text-lg"
                    >
                      {" "}
                      {loading ? <span className="loader"></span> : "Submit"}
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
          </div>

          <div className="flex gap-4 justify-center">
            <Link to="/footer/terms">
              <p className="text-richblue-100">Terms of Use</p>
            </Link>
            <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
            <Link to="/footer/privacy">
              <p className="text-richblue-100">Privacy Policy</p>
            </Link>
          </div>
        </div>

        <div>
          <img
            src={Img3}
            alt="Background"
            className="h-[100vh] hidden md:block"
          />
        </div>
      </div>
    </div>
    </>

  );
};

export default Sendotp;

{
  /* <form onSubmit={location.pathname === '/forgot_password' ? SubmitHandler1 : SubmitHandler} className='flex flex-col text-lg font-normal relative'>

                  <label htmlFor="emailInput">Enter Your Email</label>
                  <input type="email" className='border-[1px] rounded-md p-2 w-72 focus:outline-0' placeholder='Email' name="email" value={email} onChange={changehandler} />
                  <label htmlFor='passwordInput'>Password</label>
                  <input
                    type={showPassword1 ? 'text' : 'password'}
                    className='border-[1px] rounded-md p-2 w-72 focus:outline-0'
                    placeholder='Password'
                    name='password'
                    value={password}
                    onChange={changeHandler}
                  />

                  {showPassword1 ? (
                    <BiHide className='absolute top-[112px] left-[255px] cursor-pointer' onClick={toggleHandler1} />
                  ) : (
                    <BiShowAlt className='absolute top-[112px] left-[255px] cursor-pointer' onClick={toggleHandler1} />
                  )}

                  <label htmlFor='passwordInput'>Confirm password</label>
                  <input
                    type={showPassword2 ? 'text' : 'password'}
                    className='border-[1px] rounded-md p-2 w-72 focus:outline-0'
                    placeholder='Confirm Password'
                    name='confirmPassword'
                    value={confirmPassword}
                    onChange={changeHandler}
                  />

                  {showPassword2 ? (
                    <BiHide className='absolute top-[180px] left-[255px] cursor-pointer' onClick={toggleHandler2} />
                  ) : (
                    <BiShowAlt className='absolute top-[180px] left-[255px] cursor-pointer' onClick={toggleHandler2} />
                  )}

                  <div className='flex items-center justify-center mt-8'>


                    <Button variant='btn' className='drop-shadow-xl w-full py-0 px-0 text-lg'>Next</Button>

                  </div>
                </form> */
}
