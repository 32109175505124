import React, { useState } from 'react';

const Culture = () => {

    return (
        <section className="py-12 bg-white sm:py-16 font-inter lg:py-20">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl xl:text-5xl font-pj">Why Work with Bid-Drive.com </h2>
                    <p className="mt-4 text-xl font-medium leading-7 text-gray-600 sm:mt-8 font-pj">10 Reasons to Choose Us</p>
                </div>

                <div className="grid grid-cols-1 mt-10 text-center sm:mt-16 sm:grid-cols-2 sm:gap-x-12 gap-y-12  md:gap-0 xl:mt-24">
                    <div className="md:p-8 lg:p-14  md:border  md:border-gray-200">
                        <div>

                        </div>
                        <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">Personal Support</h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">We believe in a personalized approach. Our dedicated support team is always on hand to guide you through the bidding process, answer any questions, and help you make informed decisions. Whether you're a first-time buyer or a seasoned professional, we’re here to assist every step of the way.
                        </p>
                    </div>

                    <div className="md:p-8 lg:p-14 md:border  md:border-gray-200">
                        <div>

                        </div>
                        <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">After-Sales Care</h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">Our relationship doesn’t end once the auction is over. We offer comprehensive after-sales care to ensure you’re satisfied with your purchase. From managing vehicle deliveries to assisting with paperwork, we’re committed to supporting you even after the transaction is complete.

                        </p>
                    </div>

                    <div className="md:p-8 lg:p-14 md:border md:border-gray-200">
                        <div>

                        </div>
                        <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">Secure Payment Handling</h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">Your financial security is our top priority. We handle all payment transactions through secure channels, ensuring your money is safe throughout the entire process. Our integrated payment solutions also make it easy to manage transactions in your preferred currency.

                        </p>
                    </div>

                    <div className="md:p-8 lg:p-14 md:border md:border-gray-200">
                        <div>

                        </div>
                        <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">Flexible Buying Solutions</h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">Whether you’re looking for a single vehicle or buying in bulk, Bid-Drive.com offers flexible buying solutions to fit your needs. Our platform caters to businesses and individuals alike, making it the perfect choice for any scale of purchase.</p>
                    </div>

                    <div className="md:p-8 lg:p-14 md:border md:border-gray-200 md:border-t">
                        <div>

                        </div>
                        <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">Value for Money
                        </h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">At Bid-Drive.com, we offer competitive pricing and access to a vast selection of vehicles from across Europe. Our platform connects you with sellers directly, ensuring you get the best possible deal without unnecessary markups.
                        </p>
                    </div>

                    <div className="md:p-8 lg:p-14 md:border md:border-gray-200 md:border-t">
                        <div>

                        </div>
                        <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">Ongoing Innovation</h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">We’re constantly evolving to meet the needs of our customers. By investing in the latest technologies and refining our platform, we provide a seamless, user-friendly auction experience that stays ahead of industry trends.

                        </p>
                    </div>
                    <div className="md:p-8 lg:p-14 md:border md:border-gray-200 md:border-t">
                        <div>

                        </div>
                        <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">Ongoing Innovation</h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">We’re constantly evolving to meet the needs of our customers. By investing in the latest technologies and refining our platform, we provide a seamless, user-friendly auction experience that stays ahead of industry trends.

                        </p>
                    </div>
                    <div className="md:p-8 lg:p-14 md:border md:border-gray-200 md:border-t">
                        <div>

                        </div>
                        <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">Ongoing Innovation</h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">We’re constantly evolving to meet the needs of our customers. By investing in the latest technologies and refining our platform, we provide a seamless, user-friendly auction experience that stays ahead of industry trends.

                        </p>
                    </div>
                    <div className="md:p-8 lg:p-14 md:border md:border-gray-200 md:border-t">
                        <div>

                        </div>
                        <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">Ongoing Innovation</h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">We’re constantly evolving to meet the needs of our customers. By investing in the latest technologies and refining our platform, we provide a seamless, user-friendly auction experience that stays ahead of industry trends.

                        </p>
                    </div>
                    <div className="md:p-8 lg:p-14 md:border md:border-gray-200 md:border-t">
                        <div>

                        </div>
                        <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">Ongoing Innovation</h3>
                        <p className="mt-5 text-base text-gray-600 font-pj">We’re constantly evolving to meet the needs of our customers. By investing in the latest technologies and refining our platform, we provide a seamless, user-friendly auction experience that stays ahead of industry trends.

                        </p>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default Culture;